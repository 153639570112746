import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '../views/AboutView.vue'
import Holding from '@/views/auth/Holding.vue';
import LiveStreamView from '../views/LiveStreamView.vue'
import Login from '@/views/auth/Login.vue'
import NotAuthorised from "@/views/auth/notAuthorised.vue";
import Register from '@/views/auth/Register.vue'
import Reset from '@/views/auth/Reset.vue'
import Store from '@/store/index.js';
import ScheduleView from '../views/ScheduleView.vue'

import { getAuth, onAuthStateChanged } from "firebase/auth";

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Login', component: Login, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true } },
    { path: '/schedule', name: 'Schedule', component: ScheduleView, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Administration', 'Content', 'IT', 'Developers', 'Partner'] } },
    { path: '/about', name: 'About', component: AboutView, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Administration', 'Content', 'IT', 'Developers', 'Partner'] } },
    { path: '/livestream/:id?', name: 'LiveStream', component: LiveStreamView, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Administration', 'Content', 'IT', 'Developers', 'Partner'] } },

    { path: '/holding', name: 'Holding', component: Holding, meta: { requiresAuth: false, hideNavbar: true, } },
    { path: "/notauthorised", name: "NotAuthorised", component: NotAuthorised, meta: { hideNavbar: false, requiresAuth: false } },
    { path: '/register', name: 'Register', component: Register, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true } },
    { path: '/reset', name: 'Reset', component: Reset, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true } },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

router.beforeEach(async (to, from, next) => {
    if (from.name === 'LiveStream' && to.name !== 'LiveStream') {
        const answer = window.confirm('WARNING: Please stop the stream before leaving this page.')
        if (!answer) return false
    }
    if (to.meta.requiresAuth) {
        navigator.serviceWorker.register('/service-worker.js').then(reg => {
            reg.update();
        });
        const auth = getAuth();
        await onAuthStateChanged(auth, (user) => {
            if (user !== null) {
                user.getIdToken(true).then((idToken) => {
                    var token = parseJwt(idToken)
                    Store.dispatch('ACT_firebase_userAccess', idToken);
                    // if (token.user_status === 'Approved') {
                    //     let authFail = 0;
                    //     // User Group Check
                    //     // console.log('token.user_group = ' + token.user_group);
                    //     switch (true) {
                    //         case (to.meta.userGroups !== undefined && to.meta.userLevels !== undefined):
                    //             // userGroups and userLevels
                    //             if (to.meta.userGroups.includes(token.user_group) || to.meta.userLevels.includes(token.user_level)) {
                    //                 // allow passage
                    //             } else {
                    //                 // deny passage
                    //                 authFail++
                    //             }
                    //             break;
                    //         case (to.meta.userGroups !== undefined && to.meta.userLevels === undefined):
                    //             // userGroups and no userLevels
                    //             if (to.meta.userGroups.includes(token.user_group)) {
                    //                 // allow passage
                    //             } else {
                    //                 // deny passage
                    //                 authFail++
                    //             }
                    //             break;
                    //         case (to.meta.userGroups === undefined && to.meta.userLevels !== undefined):
                    //             // no userGroups and userLevels
                    //             if (to.meta.userLevels.includes(token.user_level)) {
                    //                 // allow passage
                    //             } else {
                    //                 // deny passage
                    //                 authFail++
                    //             }
                    //             break;
                    //         default:
                    //             authFail++
                    //             break;
                    //     }
                    //     // console.log('authFail=', authFail)
                    //     // If UserGroup or UserLevel check fails, redirect to NotAuthorised
                    //     if (authFail === 0) {
                    //         next();
                    //     } else {
                    //         next('/notauthorised');
                    //     }
                    // } else {
                    //     next('/holding');
                    // }
                    next();
                }).catch(error => {
                    console.error(error);
                    next('/login');
                })
                //                next();
            } else {
                next('/login');
            }
        })
    } else {
        next();
    }
})

export default router
