<template>
    <div>
        <v-card width="40vw" class="text-center rounded-lg pb-2">
            <v-card-title class="primary text-h5 white--text font-weight-light">Scheduled Live Streams</v-card-title>
            <v-card-text class="text-left body-2 mt-2"> Click Launch to get ready for your live stream. This Stream will start when you hit the Start Streaming button. </v-card-text>
            <v-row v-for="(event, index) in liveStreamEvents" :key="index" class="mx-3 mb-3 pa-2" dense style="border: 1px solid grey !important; border-radius: 5px !important">
                <v-col cols="12" class="text-left d-flex justify-start align-center">
                    <div class="text-center" style="width: 55px !important; height: 75px !important; border-radius: 5px !important" :style="'border: 1px solid ' + $vuetify.theme.themes.light.primary + ' !important;'">
                        <div class="primary pa-2 text-uppercase font-weight-bold white--text body-2">{{ MIX_formatDateTime(event.eventStartDateTime, 'X', 'MMM') }}</div>
                        <div class="white px-2 font-weight-black grey--text text--darken-2 text-h6">{{ MIX_formatDateTime(event.eventStartDateTime, 'X', 'DD') }}</div>
                    </div>
                    <div class="ml-5">
                        <div class="font-weight-bold">{{ event.eventTitle }}</div>
                        <div class="body-2">{{ event.eventDescription }}</div>
                        <div class="body-2 mt-2">
                            Start Time: <v-chip class="font-weight-bold" label small>{{ MIX_formatDateTime(event.eventStartDateTime, 'X', 'HH:mm:ss') }}</v-chip>
                            {{ MIX_formatDateTimeFromNow(event.eventStartDateTime, 'X', true) }}
                            <span v-if="$moment(event.eventStartDateTime, 'X').format('X') > $moment().format('X')">from now</span>
                            <span v-else>ago</span>
                        </div>
                    </div>
                    <div class="ml-5 flex-grow-1 d-flex justify-end">
                        <v-btn :disabled="$moment(event.eventStartDateTime, 'X').format('X') > $moment().add(15, 'minutes').format('X')" class="primary white--text" depressed @click="$router.push('/livestream/' + event.id)">
                            Open
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ScheduleView',
    data: () => ({
        liveStreamEvents: [],
        orgId: null,
        pollingInterval: null,
        POLLING_DELAY: 10000 // 10 seconds
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        })
    },
    methods: {
        async getOrgId() {
            try {
                console.log('localId:', this.GET_FIREBASE_userAuth.uid);
                const redis_user_result = await this.MIX_redisReadHash(`user:${this.GET_FIREBASE_userAuth.uid}`)
                this.orgId = redis_user_result.data.orgId
                return this.orgId
            } catch (error) {
                console.error('Error fetching orgId:', error)
                return null
            }
        },
        async getLiveStreamEvents() {
            try {
                if (!this.orgId) {
                    await this.getOrgId()
                }
                
                const startTime = this.$moment().subtract(3, 'hours').format('X')
                const searchResult = await this.MIX_redisOrderedSearch(
                    'idx:events',
                    `@orgId:${this.orgId} @eventStartDateTime:[(${startTime} inf] @eventType:{Live Stream}`,
                    0,
                    5,
                    'eventStartDateTime'
                )
                this.liveStreamEvents = searchResult.data.documents.map((x) => x.value)
            } catch (error) {
                console.error('Error fetching live stream events:', error)
            }
        },
        startPolling() {
            this.pollingInterval = setInterval(() => {
                this.getLiveStreamEvents()
            }, this.POLLING_DELAY)
        },
        stopPolling() {
            if (this.pollingInterval) {
                clearInterval(this.pollingInterval)
                this.pollingInterval = null
            }
        }
    },
    async created() {
        await this.getLiveStreamEvents()
        this.startPolling()
    },
    beforeDestroy() {
        this.stopPolling()
    }
}
</script>